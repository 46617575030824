//Staging Config -- Comment out the correct config depending on build location
const appURLtype =
  process.env.NODE_ENV === "production"
    ? "https://testtrial.home.mybe.software"
    : "http://localhost:8080";

const staging = false;

module.exports = {
  // ports
  serverPort: 8080,
  //This app url
  appURL: appURLtype,
  tenantName: "testtrial",
  staging: staging,
  supportAppUUID: "73cdffa4-3eb1-4345-b8d2-8376d9570835",
  portalAppId: "8774cf5e-8fee-4db6-9735-1bf80be2e6be"
};

// Prod Config
// const appURLtype =
//   process.env.NODE_ENV === "production"
//     ? "https://unity.portal.fe.staging.mybe.software"
//     : "http://localhost:8080";

// const staging = true;

// module.exports = {
//   // ports
//   serverPort: 8080,
//   //This app url
//   appURL: appURLtype,
//   tenantName: "Unity",
//   staging: staging,
//   supportAppUUID: "d3dcb216-ee07-46a9-80c5-83739f1266a3",
//   supportUrl: "https://unity.support.fe.staging.mybe.software"
// };
