import React, { useState, useContext } from "react";
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/Check';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Snackbar, TextField, Typography } from "@material-ui/core";
import AuthContext from "../../../services/AuthContext";
import { saveRequest } from "../../../api/deals";

export default function RequestChange({open, setOpen, dealName, brokerUuid}) {
    const [data, setData] = useState({});
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const context = useContext(AuthContext);

    const handleChange = (name, value) => {
        const temp = {...data};
        temp[name] = value;
        setData(temp);
    }

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const payload = {...data};
            payload.name = dealName;
            payload.broker_uuid = brokerUuid;
            payload.customer_id = context.user.customer_id;
            const res = await saveRequest(payload);
            if(!res.ok || res.data == undefined || res.data == null) throw "Call Failed";
            setSuccess(true);
        } catch (error) {
            setLoading(false);
            return alert(`Failed to submit request. Please try agian - ${error}`);
        }
        setLoading(false);
        setData(false);
        setOpen(false);
    }

    if(success) return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={success}
            onClose={() => {setSuccess(false)}}
            message={
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "2vw" }}>
                    <CheckIcon style={{ color: "green" }} />
                    <Typography>Request Submitted Successfully</Typography>
                </div>
            }
        />
    )

    if(loading) return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "2vh"
            }}
        >
            <CircularProgress/>
            <Typography>Loading, Please Wait...</Typography>
        </div>
    )

    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
            onClose={() => {setOpen(false)}}
        >
            <DialogTitle>
                <div 
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Typography variant="h5" gutterBottom>Request a Change</Typography>
                    <IconButton onClick={() => {setOpen(false)}}><CloseIcon/></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{ padding: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "3vw" }}>
                    <TextField
                        name="reason"
                        multiline
                        rows={3}
                        label="Reason"
                        onChange={(e) => {handleChange(e.target.name, e.target.value)}}
                        defaultValue={data?.reason !== null ? data?.reason : ""}
                        size="small"
                        fullWidth
                    />
                    <Button 
                        onClick={() => {handleSubmit()}} 
                        size="small" variant="contained" 
                        style={{ backgroundColor: "green", color: "white" }}
                        startIcon={<SaveIcon/>}
                    >
                        Submit Request
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}